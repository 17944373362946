import React from 'react';

export default function CompanyInfoItem ({ info }) {
  return (
    <div>
      <p className="mb-8 text-3xl sm:text-4xl">
        {info.content ? (
          <span>
            {info.content[0]}<br />
            {info.content[1]}<br />
            {info.content[2]}
          </span>
        ) : (
          <a href={info.url}>{info.label}</a>
        )}
      </p>
    </div>
  );
}
