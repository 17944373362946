import React from 'react';
import Layout from '../components/layout/Layout';
import companyInfos from '../data/company-infos';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import CompanyInfoItem from '../components/CompanyInfoItem';
import { Helmet } from 'react-helmet';

const Index = ({data}) => (
  <Layout>
    <Helmet>
      <title>Sebastian Klaus - Softwareentwicklung und Beratung</title>
    </Helmet>
    <section className="pt-5 md:pt-20">
      <div className="container mx-auto px-8 lg:w-1/2">
        <div>
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
      </div>
    </section>
    <section id="services" className="py-10 lg:pb-10 lg:pt-20">
      <div className="container mx-auto text-center text-xl">
        <h2 className="text-3xl lg:text-5xl font-semibold">Aktuell wird diese Webseite überarbeitet.</h2>
        <p className="mx-5 md:mx-20 my-5">Die Webseite ist demnächst wieder verfügbar.</p>
      </div>
    </section>
    <div className="container mx-auto text-center">
      <h2 className="text-3xl lg:text-5xl font-semibold">Kontakt</h2>
      {companyInfos.map(info => (
        <CompanyInfoItem info={info} />
      ))}
    </div>
  </Layout>
);

export const query = graphql`
query LogoFull {
  file(relativePath: {eq: "logo-full.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Index;
