import companyData from './company-data';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';

export default [
  {
    icon: faHome,
    content: [
      companyData.owner,
      companyData.street,
      companyData.postCode+' '+companyData.city,
      ]
  },
  {
    icon: faPhone,
    label: companyData.phoneLabel,
    url: 'tel:'+companyData.phone,
  },
  {
    icon: faEnvelope,
    label: 'info@'+companyData.urlShort,
    url: 'mailto:info@'+companyData.urlShort,
  },
]
